import React from 'react'

import useLinkTo from '@/hook/useLinkTo'
import BackBtn from './BackBtn'
import MenuBtn from './MenuBtn'
import Logo from './Logo'
import Avatar from '../Avatar'
import Search from './Search'
import { KULEWEBVIEW_ANDROID } from '@/config/constant'

const Header: React.FC<PropType> = () => {
  const { linkTo } = useLinkTo()

  const handleAvatarClick = () => {
    linkTo('/my/')
  }

  return (
    <div className="flex h-full items-center gap-sm px-md">
      <div className="flex flex-shrink-0 flex-grow-0 justify-around">
        <div className="flex h-9 w-9 sm:hidden">
          <BackBtn />
        </div>
        <div className="flex h-9 w-9">
          <MenuBtn />
        </div>
      </div>
      <div className={`flex flex-grow sm:flex-grow-0 ${KULEWEBVIEW_ANDROID ? '' : 'justify-center'}`}>
        <Logo />
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-20 items-center justify-end sm:flex-grow">
        <Search />
        {window.AppConfig.data.feature.uc.enabled && <Avatar className="w-8" onClick={handleAvatarClick} />}
      </div>
    </div>
  )
}

interface PropType {}

export default React.memo(Header)
