import React from 'react'

import useStatePopup from '@/hook/useStatePopup'
import useShare from '@/hook/useShare'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import ShareMain from './ShareMain'
import Button from '@/component/Button'

const SharePopup: React.FC<PropType> = () => {
  const { closeSharePopup } = useShare()
  const { visible, onFail } = useStatePopup(PopupSelector.sharePopup)

  const handleClose = () => {
    onFail && onFail()
    closeSharePopup()
  }

  return (
    <Drawer
      visible={visible}
      placement="bottom"
      zIndex={910}
      onClose={handleClose}
      lockScroll={true}
      destroyOnClose
      height={192}
      title={'Battle with your friend'}
      closable={false}
      className="bg-white"
    >
      <div className="flex h-full flex-col p-sm px-md">
        <div className="flex-grow">
          <ShareMain />
        </div>
        <Button className="w-full" onClick={handleClose}>
          Cancel
        </Button>
      </div>
    </Drawer>
  )
}

interface PropType {}

export default SharePopup
