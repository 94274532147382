import React from 'react'

import { BaseGameType } from '@kulee/tga-sdk/types/types/game'
import Picture from '@/component/Picture'
import LinkEnhance from '@/component/LinkEnhance'

const SearchGameItem: React.FC<PropType> = ({ game, active = false }) => {
  const { code, pvp } = game

  const href = pvp ? `/battle/${code}/` : `/play/${code}/`

  return (
    <LinkEnhance
      className={`flex h-16 items-center gap-sm px-md hover:bg-primary-light/50 ${active && 'bg-primary-light/50'}`}
      href={href}
    >
      <div className="flex-shrink-0 flex-grow-0 basis-14 overflow-hidden rounded-xl">
        <Picture src={game.icon} />
      </div>
      <div className="overflow-hidden text-ellipsis text-nowrap font-custom-bold text-primary-text">
        {game.name}
        {pvp && ' (PVP)'}
      </div>
    </LinkEnhance>
  )
}

interface PropType {
  game: BaseGameType
  active?: boolean
}

export default SearchGameItem
