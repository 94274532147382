const EventNames = {
  // websocket链接
  WS_OPEN: 'pg.ws.open',
  // websocket关闭
  WS_CLOSE: 'pg.ws.close',
  // websocket错误
  WS_ERROR: 'pg.ws.error',
  // 心跳
  HEART_BIT: 'pg.heartbeat',
  // 匹配成功
  MATCH_SUCCESS: 'pg.match.success',
  // 房间信息变化
  GAME_ROOM_CHANGE: 'pg.room.change',
  // 进入房间
  GAME_ROOM_ENTER: 'pg.room.enter',
  // 退出房间
  GAME_ROOM_QUIT: 'pg.room.quit',
  // 所有玩家已准备
  GAME_ROOM_ALLREADY: 'pg.room.allready',
  // 玩家取消准备
  GAME_ROOM_CANCELREADY: 'pg.room.cancelready',
  // 玩家开始倒计时
  GAME_ROOM_COUNTDOWN: 'pg.room.countdown',
  // 玩家压注
  GAME_ROOM_BETDOUBLE: 'pg.room.betduble',
  // 玩家压注
  GAME_ROOM_OFFLINE: 'pg.room.offline',
  // 玩家压注
  GAME_ROOM_ONLINE: 'pg.room.online',
  // 创建游戏对局
  GAME_BATTLE_CREATE: 'pg.game.battle.create',
  // 关闭游戏
  GAME_BATTLE_CLOSE: 'pg.game.battle.close',
  // 加载游戏
  GAME_LOAD: 'pg.game.load',
  // 游戏加载进度
  GAME_LOAD_PROGRESS: 'pg.game.load.progress',
  // 游戏准备就绪
  GAME_START: 'pg.game.start',
  // 游戏结束
  GAME_FINISH: 'pg.game.finish',
  // 退出游戏
  GAME_QUIT: 'pg.game.quit'
} as const

export type EventNameType = (typeof EventNames)[keyof typeof EventNames]

export default EventNames
