import React from 'react'
import { useTranslation } from 'react-i18next'

import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '@/component/Drawer'
import AuthRequired from '@/component/AuthRequired'
import DiamondBalance from './DiamondBalance'
import DiamondCommodityList from './DiamondCommodityList'

const TopUpDiamondPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const topUpDiamondPopupVisible = useStatePopup(PopupSelector.topUpDiamondPopupVisible)

  const handleClose = () => {
    PopUpStore.setTopUpDiamondPopupVisible(false)
  }

  return (
    <Drawer
      visible={topUpDiamondPopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.topUpDiamond')}
    >
      <AuthRequired>
        <div className="px-md">
          <DiamondBalance />
          <DiamondCommodityList />
        </div>
      </AuthRequired>
    </Drawer>
  )
}

interface PropType {}

export default TopUpDiamondPopup
