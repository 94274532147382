import ReconnectingWebSocket from 'reconnecting-websocket'

import PKGameService from './Service'
import BaseModule from '../../BaseModule'
import logger from '../../config/logger'
import { CMDS } from './constant'

import type { Context } from '../../types'
import type { WSMessage } from '../../types/PKGame'

class WS extends BaseModule {
  private service!: PKGameService

  private instance: ReconnectingWebSocket | null = null
  private onOpen: (event: Event) => void
  private onError: (event: ErrorEvent) => void
  private onClose: (event: CloseEvent) => void
  private onMessage: <T>(data: { cmd: CMDS; payload: T }) => void
  private heartBitTimeID: ReturnType<typeof setTimeout> | null = null
  private heartBitStartTime: number | null = null

  constructor(context: Context, options: WSOptions) {
    super(context)

    const { onOpen = () => {}, onError = () => {}, onClose = () => {}, onMessage = () => {} } = options
    this.onOpen = onOpen
    this.onError = onError
    this.onClose = onClose
    this.onMessage = onMessage
  }

  /**
   * 初始化
   */
  public async init() {
    this.service = new PKGameService(this.context)

    return new Promise(resolve => {
      this.close()

      const wsUrl = this.service.getWSUrl()
      const ws = new ReconnectingWebSocket(wsUrl)

      ws.onopen = event => {
        this.instance = ws
        this.handleOpen(event)

        resolve(null)
      }
      ws.onerror = this.onError
      ws.onclose = event => {
        this.instance = null

        this.onClose(event)
      }

      ws.onmessage = this.handleMessage.bind(this)
    })
  }

  /**
   * 关闭
   */
  public close() {
    if (this.heartBitTimeID) {
      clearInterval(this.heartBitTimeID)
      this.heartBitStartTime = null
    }

    this.instance && this.instance.close()
  }

  /**
   * 发送消息
   */
  public sendMessage<T>({ cmd, module = 'BATTLE', payload }: { cmd: CMDS; module?: 'BATTLE' | 'SYSTEM'; payload?: T }) {
    const data = {
      module,
      cmd,
      payload
    }
    cmd !== CMDS.HEART_BIT && logger.log(`send message ${cmd}, payload:`, data)

    if (this.instance) {
      this.instance.send(JSON.stringify(data))
    } else {
      logger.error('init websocket first')
    }
  }

  private handleOpen(event: Event) {
    logger.log('ws onopen', event)

    this.ping()
    this.onOpen(event)
  }

  private ping() {
    if (this.heartBitTimeID) {
      clearInterval(this.heartBitTimeID)
      this.heartBitStartTime = null
    }

    this.heartBitTimeID = setInterval(() => {
      this.heartBitStartTime = new Date().getTime()
      this.sendMessage({
        module: 'SYSTEM',
        cmd: CMDS.HEART_BIT
      })
    }, 2000)
  }

  private handleMessage(event: MessageEvent) {
    const data: WSMessage = JSON.parse(event.data)
    const { cmd, payload: payloadStr = null } = data
    const payload = JSON.parse(payloadStr)

    if (payload?.code) {
      logger.error(`message error`, payload)
      return
    }

    if (cmd === CMDS.HEART_BIT) {
      const time = new Date().getTime() - (this.heartBitStartTime || 0)
      this.onMessage({
        cmd: CMDS.HEART_BIT,
        payload: time
      })
    } else {
      this.onMessage({
        cmd,
        payload
      })
    }
  }
}

interface WSOptions {
  onOpen?: (event: Event) => void
  onError?: (event: ErrorEvent) => void
  onClose?: (event: CloseEvent) => void
  onMessage?: (data: { cmd: CMDS; payload: any }) => void
}

declare class Event {
  target: any
  type: string
  constructor(type: string, target: any)
}

declare class ErrorEvent extends Event {
  message: string
  error: Error
  constructor(error: Error, target: any)
}
declare class CloseEvent extends Event {
  code: number
  reason: string
  wasClean: boolean
  constructor(code: number | undefined, reason: string | undefined, target: any)
}

export default WS
