import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { VIP } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import Loading from '@/component/Loading'
import Empty from '@/component/Empty'
import type { VIPOrder } from '@kulee/tga-sdk/types/types/vip'
import VIPOrderItem from '../VIPOrderItem'

const PAGE_SIZE = 10

const VIPOrderMain: React.FC<PropType> = () => {
  const { t } = useTranslation()
  const [orders, setOrders] = useState<VIPOrder[]>()
  const [pageNum, setPageNum] = useState<number>(1)
  const [hasMore, setHasMore] = useState(true)

  const handleRemoveOrder = (order: VIPOrder) => {
    const newOrders = orders?.filter(item => item.orderNo !== order.orderNo)
    setOrders(newOrders)
  }

  const handleCancelOrder = (order: VIPOrder) => {
    if (!orders) {
      return
    }

    const item = orders?.find(item => item.orderNo === order.orderNo)
    item && (item.status = 'canceled')

    setOrders([...orders])
  }

  const handlePayOrder = (order: VIPOrder) => {
    if (!orders) {
      return
    }

    const item = orders?.find(item => item.orderNo === order.orderNo)
    item && (item.status = 'completed')

    setOrders([...orders])
  }

  const fetchData = async () => {
    const { data, total } = await TGASDK.module.VIP.service.getVIPOrders({
      pageNum: pageNum,
      pageSize: PAGE_SIZE
    })
    setHasMore(pageNum * PAGE_SIZE < total)
    setPageNum(pageNum + 1)
    setOrders([...(orders ? orders : []), ...data])
  }

  useEffect(() => {
    TGASDK.addModule(VIP)
    fetchData()
  }, [])

  if (!orders) {
    return <Loading className="h-40" />
  }

  if (orders.length <= 0) {
    return <Empty className="pt-24" />
  }

  const loader = <div className="text-center">{t('tip.loadMore')}</div>
  const endMessage = <div className="text-center">{t('tip.nothingMore')}</div>
  const eleId = 'vipOrders'

  return (
    <div id={eleId} className="h-[calc(100vh-3.5rem)] overflow-auto px-md py-sm">
      <InfiniteScroll
        dataLength={orders.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader}
        endMessage={endMessage}
        scrollableTarget={eleId}
      >
        {orders.map(item => (
          <VIPOrderItem
            key={item.orderNo}
            item={item}
            onRemove={handleRemoveOrder}
            onCancel={handleCancelOrder}
            onPay={handlePayOrder}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
}

interface PropType {}

export default VIPOrderMain
