import logger from './config/logger'
import getRequestInstance from './config/request'

import type { Context, ModuleType, TGASDKOptions } from './types'
import type BaseModule from './BaseModule'

class TGASDK {
  private static ready = false
  private static context: Context = {} as Context

  public static module: ModuleType = {} as ModuleType

  public static init(options: TGASDKOptions) {
    const { env, appConfig } = options
    const { appId, appCode, lang } = appConfig
    const request = getRequestInstance({
      env,
      appId,
      appCode,
      lang
    })

    this.context.env = env
    this.context.lang = lang
    this.context.request = request
    this.context.appConfig = appConfig

    this.ready = true
    logger.log('TGASDK inited.')
  }

  public static addModule<T extends BaseModule>(ModuleCtor: new (context: Context) => T): T {
    // @ts-ignore
    const clazz: string = ModuleCtor.clazz

    if (Object.prototype.hasOwnProperty.call(this.module, clazz)) {
      return this.module[clazz] as T
    }

    if (!this.ready) {
      throw new Error('Please call "TGASDK.init()" first!')
    }

    const moduleInstance = new ModuleCtor(this.context)
    this.module[clazz] = moduleInstance

    moduleInstance.run()

    return moduleInstance
  }
}

export default TGASDK
