import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'

const Input = React.forwardRef<HTMLInputElement, PropType>((props, outerRef) => {
  const {
    value,
    disabled,
    allowClear = false,
    onChange,
    onPressEnter,
    onFocus,
    onBlur,
    onKeyUp,
    prefix,
    className,
    ...rest
  } = props

  const innerRef = useRef<HTMLInputElement>(null)
  const onCompositionRef = useRef(false)
  const onChangeDataRef = useRef<React.ChangeEvent<HTMLInputElement>>()

  const [iptValue, setIptValue] = useState<string | number | readonly string[] | undefined>()
  const [clearBtnStatus, setClearBtnStatus] = useState(false)
  const [focus, setFocus] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeDataRef.current = e

    if (onCompositionRef.current) {
    } else {
      const value = e.target.value
      triggerClearBtnStatusChange(value)
      onChange && onChange(e)
    }

    setIptValue(e.target.value)
  }

  const handleComposition = (e: React.CompositionEvent<HTMLInputElement>) => {
    if (e.type === 'compositionend') {
      onCompositionRef.current = false

      if (onChangeDataRef.current) {
        const value = onChangeDataRef.current.target.value
        triggerClearBtnStatusChange(value)
        onChange && onChange(onChangeDataRef.current)
      }
    } else {
      onCompositionRef.current = true
    }
  }

  const handleClear = () => {
    setIptValue('')

    innerRef.current?.focus()
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocus(true)

    if (e.relatedTarget?.getAttribute('data-input') !== 'input') {
      onFocus && onFocus(e)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setFocus(false)

    if (e.relatedTarget?.getAttribute('data-input') !== 'input') {
      onBlur && onBlur(e)
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    onKeyUp && onKeyUp(e)
    key === 'Enter' && onPressEnter && onPressEnter(e)
  }

  const triggerClearBtnStatusChange = (value?: string | number | readonly string[]) => {
    allowClear && setClearBtnStatus(!!value)
  }

  useEffect(() => {
    setIptValue(value)
  }, [value])

  useEffect(() => {
    triggerClearBtnStatusChange(iptValue)
  }, [focus])

  useImperativeHandle(outerRef, () => innerRef.current!, [])

  const classNames = twMerge(
    'flex h-9 w-full items-center rounded-full border bg-white transition',
    disabled && 'border-gray-200',
    disabled ? 'text-gray-dark' : 'text-gray-700',
    focus ? 'border-primary' : 'border-gray-300',
    className
  )

  return (
    <div className={classNames}>
      <div className="flex flex-grow items-center px-3">
        {prefix && <div className="mr-2">{prefix}</div>}
        <input
          ref={innerRef}
          value={iptValue || ''}
          className="w-full flex-grow bg-transparent text-sm caret-primary outline-none disabled:bg-transparent"
          onChange={handleChange}
          onCompositionStart={handleComposition}
          onCompositionUpdate={handleComposition}
          onCompositionEnd={handleComposition}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          {...rest}
        />
        {clearBtnStatus && (
          <span className="flex w-[26px] items-center justify-center">
            <button data-input="input" className="aspect-square w-full rounded-full" onClick={handleClear}>
              <CloseSVG className="mx-auto w-5/6 fill-gray-700" />
            </button>
          </span>
        )}
      </div>
    </div>
  )
})

type PropType = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  allowClear?: boolean
  prefix?: any
  onPressEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export default Input
