import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Button from '@/component/Button'

const BlankCard: React.FC<PropType> = ({ maxHeight, overflowType = 'scroll', className, children }) => {
  const { t } = useTranslation()
  const outerRef = useRef<HTMLDivElement>(null)
  const innterRef = useRef<HTMLDivElement>(null)
  const [btnVisible, setBtnVisible] = useState(false)
  const [opened, setOpened] = useState(false)

  const handleClick = () => {
    setOpened(true)
  }

  useEffect(() => {
    if (
      maxHeight &&
      overflowType === 'btn' &&
      innterRef.current &&
      outerRef.current &&
      innterRef.current.offsetHeight > outerRef.current.offsetHeight
    ) {
      setBtnVisible(true)
    }
  })

  const classNames = twMerge('article rounded-2xl border border-fontcolor-200 p-md pb-2', className)

  return (
    <div className={classNames}>
      <div
        ref={outerRef}
        style={{ maxHeight }}
        className={`mb-3 ${opened ? '!max-h-none' : ''} ${overflowType === 'scroll' ? 'overflow-y-auto' : 'overflow-y-hidden'}`}
      >
        <div ref={innterRef}>{children}</div>
      </div>
      {btnVisible && !opened && (
        <Button size="small" type="link" className="w-full" onClick={handleClick}>
          {t('btn.showMore')}
        </Button>
      )}
    </div>
  )
}

interface PropType {
  maxHeight?: string
  overflowType?: 'scroll' | 'btn'
  className?: string
  children: React.ReactNode
}

export default BlankCard
