import { EventBus } from '@kulee/helper'

import BaseModule from '../../BaseModule'
import PKGameService from './Service'
import logger from '../../config/logger'

import { CMDS, DEFAULT_USER } from './constant'
import EventNames, { EventNameType } from './EventNames'
import WS from './WS'
import MessageHandler from './MessageHandler'

import type { BaseGameType } from '../../types/game'
import type { UserInfo } from '../../types/uc'
import type { GameRoomInfo, PKGameContext } from '../../types/PKGame'

class PKGame extends BaseModule {
  public static override clazz = 'PKGame'
  public static EventNames = EventNames

  public service!: PKGameService

  private wsInstance!: WS
  private messageHandler!: MessageHandler

  private user!: UserInfo
  private gameInfo!: BaseGameType
  private gameRoom!: GameRoomInfo

  private userOfflineTime = 0
  private userQuitTime = 0

  public override run(): void {
    logger.log(`run module ${PKGame.clazz}`)

    this.service = new PKGameService(this.context)

    this.messageHandler = new MessageHandler({
      dispatch: this.dispatch,
      sendGameData: data => {
        this.SDK.module.GameInteraction.sendBattleGameData(data)
      },
      onGameRoomChange: gameRoom => {
        this.gameRoom = gameRoom
      },
      getGameRoom: () => {
        return this.gameRoom || {}
      }
    })
    this.wsInstance = new WS(this.context, {
      onOpen: event => {
        this.dispatch(EventNames.WS_OPEN, event)
      },
      onClose: event => {
        this.dispatch(EventNames.WS_CLOSE, event)
      },
      onError: event => {
        this.dispatch(EventNames.WS_ERROR, event)
      },
      onMessage: data => {
        this.messageHandler.handle(data)
      }
    })
  }

  public addEventListener(eventName: EventNameType, callback: (...args: any[]) => void) {
    EventBus.addEventListener(eventName, callback)
  }

  public removeEventListener(eventName: EventNameType, callback?: (...args: any[]) => void) {
    EventBus.removeEventListener(eventName, callback)
  }

  public dispatch(eventName: EventNameType, ...args: any[]) {
    if (eventName === EventNames.GAME_ROOM_OFFLINE) {
      this.userOfflineTime = args[0]
      EventBus.dispatch(eventName, this.userOfflineTime)
    } else if (eventName === EventNames.GAME_QUIT) {
      this.userQuitTime = args[0]
      EventBus.dispatch(eventName, this.userQuitTime)
    } else if (eventName === EventNames.GAME_ROOM_ONLINE) {
      this.userOfflineTime = 0
      EventBus.dispatch(eventName, ...args)
    } else {
      EventBus.dispatch(eventName, ...args)
    }
  }

  public getContext(): PKGameContext {
    return {
      gameRoom: this.gameRoom,
      userOfflineTime: this.userOfflineTime,
      userQuitTime: this.userQuitTime
    }
  }

  /**
   * 配置
   */
  public config(params: ConfigParams) {
    const { user, gameInfo } = params

    if (user) {
      this.user = user
      this.messageHandler.setUser(user)
    }
    if (gameInfo) {
      this.gameInfo = gameInfo
    }
  }

  // 初始化websocket
  public async initWS() {
    return this.wsInstance.init()
  }

  /**
   * 匹配
   */
  public async match() {
    this.reset()

    return this.service.match({ gameId: this.gameInfo.id })
  }

  /**
   * 创建房间
   * @param roomNo 房间号
   */
  public async createGameRoom(roomNo: string) {
    this.reset()

    return this.service.createGameRoom(roomNo)
  }

  /**
   * 进入房间
   * @param roomNo 房间号
   */
  public enterGameRoom(roomNo: string) {
    this.reset(roomNo)

    return this.service.enterGameRoom({
      gameId: this.gameInfo.id,
      roomNo
    })
  }

  /**
   * 创建游戏对局
   * @param roomNo 房间号
   */
  public createGameBattle() {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_BATTLE_CREATE,
      payload: {
        roomNo: this.gameRoom.roomNo
      }
    })
  }

  /**
   * 关闭对局
   */
  public closeGameBattle() {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_BATTLE_CLOSE
    })
  }

  /**
   * 退出
   */
  public async quit() {
    try {
      if (this.gameRoom.status === 'none') {
        await this.service.quitMatch()
      } else {
        await this.service.quitGame()
      }
      this.gameRoom.roomNo && (await this.service.quitGameRoom(this.gameRoom.roomNo))
    } catch (error) {
      logger.warn('quit error', error)
    }
  }

  public async quitAndCloseWS() {
    this.wsInstance.close()

    await this.quit()
  }

  /**
   * 用户准备
   */
  public userReady() {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_ROOM_USER_READY
    })
  }

  /**
   * 用户取消准备
   */
  public userCancelReady() {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_ROOM_USER_CANCEL_READY
    })
  }

  /**
   * 开始游戏倒计时，房主调
   */
  public gameCountDown() {
    if (this.gameRoom.owner === this.user.userId) {
      this.wsInstance.sendMessage({
        cmd: CMDS.GAME_ROOM_COUNTDOWN
      })
    }
  }

  /**
   * 倒计时完成，可以开始加载游戏
   */
  public gameCountDownFinish(): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_ROOM_COUNTDOWN_FINISH
    })
  }

  /**
   * 加注
   */
  public betDouble(): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_ROOM_BETDOUBLE
    })
  }

  /**
   * 通知对手自己的游戏加载进度
   * @param progress
   */
  public gameLoadProgress(progress: number): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_DATA,
      payload: {
        data: {
          __cmd: 'progress',
          payload: {
            userId: this.user.userId,
            progress
          }
        },
        exceptSelf: false
      }
    })
  }

  /**
   * 游戏加载完成，准备就绪
   */
  public gameReady(): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_LOADED
    })
  }

  /**
   * 游戏结束
   */
  public gameFinish(winner: string): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_FINISH,
      payload: {
        userIds: winner
      }
    })
  }

  /**
   * 转发游戏数据
   */
  public sendGameData(data: string, exceptSelf = false): void {
    this.wsInstance.sendMessage({
      cmd: CMDS.GAME_DATA,
      payload: {
        data,
        exceptSelf
      }
    })
  }

  private reset(roomNo = '') {
    if (this.gameRoom?.roomNo === roomNo) {
      return
    }

    this.userOfflineTime = 0
    this.userQuitTime = 0

    const { userId, nickname, avatar } = this.user
    this.gameRoom = {
      roomNo,
      gameId: this.gameInfo?.id || '',
      status: 'none',
      owner: '',
      allReady: false,
      allBet: false,
      full: false,
      teams: [
        {
          id: '1',
          user: [{ ...DEFAULT_USER, userId, nickname, avatar, role: 'admin' }]
        },
        {
          id: '2',
          user: [DEFAULT_USER]
        }
      ]
    }
    this.dispatch(EventNames.GAME_ROOM_CHANGE, this.gameRoom)
  }
}

interface ConfigParams {
  user?: UserInfo
  gameInfo?: BaseGameType
}

export default PKGame
