import { createRoot } from 'react-dom/client'
const animationTime = 260 // 动画时间，单位（ms）
const appearAnimation = [{ opacity: 0 }, { opacity: 1 }] // 淡入动画效果
const disappearAnimation = [{ opacity: 1 }, { opacity: 0 }] // 淡出动画效果

const eleId = 'toast-box'

const renderToastInBody = function (component: JSX.Element) {
  // 创建一个 div
  const nextToastDiv = document.createElement('div')
  nextToastDiv.id = eleId
  // 如果之前存在已渲染的 Toast 组件，则使用 replaceChild 进行替换
  const presentToastDiv = document.getElementById(eleId)
  if (presentToastDiv && document.body.contains(presentToastDiv)) {
    document.body.replaceChild(nextToastDiv, presentToastDiv)
  }
  // 否则使用 appendChild 添加在 body 里面
  else {
    document.body.appendChild(nextToastDiv)
    nextToastDiv.animate(appearAnimation, animationTime)
  }
  // 通过 createRoot 将 React 组件渲染到浏览器 DOM 节点里面
  const root = createRoot(nextToastDiv)
  root.render(component)
  return () => {
    nextToastDiv.animate(disappearAnimation, animationTime).onfinish = function () {
      root.unmount()
      // 容错处理
      if (document.body.contains(nextToastDiv)) document.body.removeChild(nextToastDiv)
    }
  }
}

/** 移除当前的 Toast 组件 */
const removeToastInBody = function () {
  const presentToastDiv = document.getElementById(eleId)
  if (presentToastDiv && document.body.contains(presentToastDiv)) {
    presentToastDiv.animate(disappearAnimation, animationTime).onfinish = function () {
      document.body.removeChild(presentToastDiv)
    }
  }
}

export { renderToastInBody, removeToastInBody }
