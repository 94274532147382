import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import logger from '@/helper/logger'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'
import Button from '@/component/Button'
import Divider from '@/component/Divider'
import PayChannelList from '../PayChannelList'
import PayResult from '../PayResult'
import Price from '@/component/Price'
import Skeleton from '@/component/Skeleton'

import type { BaseCommodityType, BussnessOrderType, Payment } from '@kulee/tga-sdk/types/types/wallet'

const PayMain: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const {
    payOrderNo,
    commodity: commodityProp,
    createOrder,
    onSuccess,
    onError
  } = useStatePopup(PopupSelector.payPopup)
  const [order, setOrder] = useState<BussnessOrderType>()
  const [commodity, setCommodity] = useState<BaseCommodityType | undefined>(commodityProp)
  const [payment, setPayment] = useState<Payment>()

  const getCommodityMutation = useMutation({
    mutationFn: async (payOrderNo: string) => {
      const { data } = await TGASDK.module.Wallet.service.getPayOrderInfo(payOrderNo)
      const { orderNo, amount, commodity } = data
      const { desc, ext } = commodity
      setOrder({
        orderNo,
        payOrderNo,
        paymentCode: '',
        status: 'pending',
        amount,
        desc,
        ext
      })
      return commodity
    }
  })

  const payMutation = useMutation({
    mutationFn: async (payment: Payment) => {
      let orderNew = order
      // 创建订单
      if (!orderNew && createOrder) {
        orderNew = await createOrder(payment.code)
        setOrder(orderNew)
      }

      if (!orderNew) {
        throw new Error('')
      }

      await TGASDK.module.Wallet.pay({
        ...orderNew,
        paymentCode: payment.code
      })

      return orderNew
    }
  })

  const handlePaymentChange = useCallback((payment: Payment) => {
    setPayment(payment)
  }, [])

  const handlePayClick = async () => {
    if (!commodity || !payment) {
      return
    }
    payMutation.mutate(payment, {
      onSuccess: order => {
        order && onSuccess && onSuccess(order)
      },
      onError(error) {
        logger.error('pay error', error)
        onError && onError(error)
      }
    })
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)

    if (!commodity && payOrderNo) {
      getCommodityMutation.mutate(payOrderNo, {
        onSuccess(data) {
          setCommodity(data)
        }
      })
    }
  }, [])

  return (
    <div className="flex h-full flex-col px-md pb-md">
      {(payMutation.isIdle || payMutation.isPending) && (
        <>
          <div className="h-14 overflow-hidden text-center">
            {getCommodityMutation.isPending ? (
              <Skeleton type="paragraph" row={1} className="mx-auto flex w-1/2 items-center justify-center" />
            ) : (
              <>
                <Price
                  currencyCode={commodity?.payCurrency}
                  currencyPrefix={commodity?.payCurrencyPrefix || ''}
                  price={commodity?.price || 0}
                  size="large"
                  className="text-primary-dark"
                />
                <div>{commodity?.name}</div>
              </>
            )}
          </div>
          <Divider />
          <div className="flex flex-grow flex-col justify-between">
            <PayChannelList payCurrency={commodity?.payCurrency} payment={payment} onChange={handlePaymentChange} />
            <div>
              <Button
                type="primary"
                size="large"
                className="w-full"
                disabled={!payment}
                loading={payMutation.isPending}
                onClick={handlePayClick}
              >
                {t('btn.pay')}
              </Button>
            </div>
          </div>
        </>
      )}
      {/* {JSON.stringify(payMutation.error?.message)}
      {JSON.stringify(payMutation.error?.stack)} */}
      {payMutation.isSuccess && <PayResult type="success" order={order} payment={payment} />}
      {payMutation.isError && <PayResult type="fail" order={order} payment={payment} />}
    </div>
  )
}

interface PropType {}

export default PayMain
