import React, { useContext } from 'react'

import VIPContext from '@/context/VIPContext'
import Empty from '@/component/Empty'
import VIPBenefitItem from '../VIPBenefitItem'

const VIPBenefitMain: React.FC<PropType> = () => {
  const { userVIPBenefits } = useContext(VIPContext)

  if (userVIPBenefits.length <= 0) {
    return <Empty className="pt-24" />
  }

  return (
    <div className="px-md">
      {userVIPBenefits.map(item => (
        <VIPBenefitItem key={item.id} item={item} />
      ))}
    </div>
  )
}

interface PropType {}

export default VIPBenefitMain
