import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AuthContext from '@/context/AuthContext'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '../../Drawer'
import Input from '../../Input'
import Button from '../../Button'

const EditNicknamePopup: React.FC<PropType> = () => {
  const { t } = useTranslation(['translation', 'uc'])
  const { user, updateUser } = useContext(AuthContext)
  const editNicknamePopupVisible = useStatePopup(PopupSelector.editNicknamePopupVisible)
  const [nickname, setNickname] = useState(user?.nickname)
  const [disable, setDisable] = useState(false)
  const [status, setStatus] = useState<'ready' | 'loading' | 'success' | 'fail'>()

  const validator = (value?: string): boolean => {
    if (!value) {
      return false
    }
    return /^[a-zA-Z0-9._]{6,20}$/.test(value)
  }

  const handleClose = () => {
    PopUpStore.setEditNicknamePopupVisible(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStatus('ready')
    setNickname(e.target.value)
  }

  const handleSaveClick = async () => {
    setStatus('loading')
    try {
      await updateUser({
        nickname
      })
      setStatus('success')
    } catch (error) {
      setStatus('fail')
    }
  }

  useEffect(() => {
    setDisable(!validator(nickname))
  }, [nickname])

  return (
    <Drawer
      visible={editNicknamePopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.editNickname', { ns: 'uc' })}
    >
      <div className="px-md">
        <div className="flex flex-col gap-md">
          <h4 className="text-center text-base">{t('tip.editNickname', { ns: 'uc' })}</h4>
          <Input value={nickname} onChange={handleInputChange} />
          {status === 'success' && (
            <p className="text-center text-semantic-success">{t('message.editNicknameSuccess', { ns: 'uc' })}</p>
          )}
          {status === 'fail' && (
            <p className="text-center text-semantic-error">{t('message.editNicknameFail', { ns: 'uc' })}</p>
          )}
          <Button
            className="w-full"
            type="primary"
            onClick={handleSaveClick}
            loading={status === 'loading'}
            disabled={disable}
          >
            {t('btn.save')}
          </Button>
          <p className="text-fontcolor-400">{t('tip.editNicknameRule', { ns: 'uc' })}</p>
        </div>
      </div>
    </Drawer>
  )
}

interface PropType {}

export default EditNicknamePopup
