import { KULEWEBVIEW } from '@/config/constant'
import { PopUpStore } from '@/store/PopupStore'

import type { ShareChannel, ShareParams } from '@/type/share'

const useShare = () => {
  const { enabled, channels: config = [] } = window.AppConfig.data.feature.share
  // const { enabled, channels: config = [] } = {
  //   enabled: true,
  //   channels: ['xx']
  // }

  const channels: string[] = []

  if (enabled) {
    config.map(item => {
      return item !== 'inApp' ? channels.push(item) : KULEWEBVIEW ? channels.push(item) : null
    })
  }

  const shareInApp = async (params: ShareParams): Promise<void> => {
    const { type, icon: iconUrl, title, url } = params

    const res = await window.Bridge.share.shareInside({
      type,
      iconUrl,
      title,
      url
    })

    if (res === null) {
      return
    } else {
      throw new Error('share fail')
    }
  }

  const share = async (channel: ShareChannel, params: ShareParams): Promise<void> => {
    switch (channel) {
      case 'inApp':
        return shareInApp(params)
      default:
        break
    }
  }

  const showSharePopup = async (options: { params: ShareParams; onSuccess?: () => void; onFail?: () => void }) => {
    if (channels.length <= 0) {
      return
    }

    const { params, onSuccess, onFail } = options

    // 开发测试
    // onSuccess && onSuccess()

    if (channels.length === 1) {
      try {
        await share(channels[0] as any, params)
        onSuccess && onSuccess()
      } catch (error) {
        onFail && onFail()
      }
    } else {
      PopUpStore.setSharePopup({
        visible: true,
        params,
        onSuccess,
        onFail
      })
    }
  }

  const closeSharePopup = () => {
    PopUpStore.setSharePopup({
      visible: false,
      params: undefined,
      onSuccess: undefined,
      onFail: undefined
    })
  }

  return {
    channels,
    share,
    showSharePopup,
    closeSharePopup
  }
}

export default useShare
