import React from 'react'

import useStatePopup from '@/hook/useStatePopup'
import useThemeBasis from '@/hook/useThemeBasis'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import { ReactComponent as MenuSVG } from '@/asset/svg/menu.svg'

const MenuBtn: React.FC<PropType> = () => {
  const menuPopupVisible = useStatePopup(PopupSelector.menuPopupVisible)
  const { lightHeader, lightAndroidHeader } = useThemeBasis()

  const handleClick = () => {
    PopUpStore.setMenuPopupVisible(!menuPopupVisible)
  }

  return (
    <button className="flex h-full w-full items-center justify-center" onClick={handleClick}>
      <MenuSVG className={`w-7 ${menuPopupVisible ? 'rotate-180' : ''}`} />
    </button>
  )
}

interface PropType {}

export default MenuBtn
