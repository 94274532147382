import { removeToastInBody, renderToastInBody } from './render'

import ToastComponent from './ToastComponent'

const Toast = {
  show: (options: ToastShowOptions) => {
    const { content, icon, duration, maskClickable } = options

    const close = renderToastInBody(
      <ToastComponent
        content={content}
        icon={icon}
        duration={duration}
        maskClickable={maskClickable}
        onClose={() => {
          close()
        }}
      />
    )

    return close
  },
  clear: () => {
    removeToastInBody()
  },
  config: () => {}
}

interface ToastShowOptions {
  content: React.ReactNode
  icon?: 'success' | 'fail' | 'loading'
  maskClickable?: boolean
  duration?: number
}

export default Toast
