import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { Wallet } from '@kulee/tga-sdk'

import TGASDK from '@/config/TGASDK'
import WalletContext from '@/context/WalletContext'
import useChargeCommodity from '@/hook/data/useChargeCommodity'
import { PopUpStore } from '@/store/PopupStore'
import Button from '@/component/Button'
import GoldExchangeInput from '../GoldExchangeInput'
import Loading from '@/component/Loading'
import Empty from '@/component/Empty'
import Message from '@/component/Message'

import type { ChargeCommodity } from '@kulee/tga-sdk/types/types/wallet'

const GoldExchange: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const { wallet, init: walletInit } = useContext(WalletContext)
  const { data: commodities } = useChargeCommodity('GOLD', 'DIAMOND', 1)
  const [quantity, setQuantity] = useState(0)

  const mutation = useMutation({
    mutationFn: async (commodity: ChargeCommodity) => {
      // 查询支付渠道
      const { data } = await TGASDK.module.Wallet.service.getPayments({
        currencyCode: commodity.payCurrency,
        platform: 'Web'
      })

      // 创建订单，自动支付
      return TGASDK.module.Wallet.service.createChargeOrder({
        commodityId: commodity.id,
        quantity,
        autoPay: true,
        paymentCode: data[0].code
      })
    }
  })

  const handleExchangeInputChange = useCallback((value: number) => {
    mutation.reset()
    setQuantity(value)
  }, [])

  const handleExhcangeClick = async () => {
    if (!commodities || commodities.length <= 0) {
      return
    }

    mutation.mutate(commodities[0], {
      onSuccess: () => {
        // 刷新钱包
        walletInit()
      }
    })
  }

  useEffect(() => {
    TGASDK.addModule(Wallet)
  }, [])

  if (typeof commodities === 'undefined') {
    return <Loading className="h-40" />
  }

  if (commodities === null || commodities.length <= 0) {
    return <Empty className="pt-24" />
  }

  return (
    <div className="flex flex-col gap-md">
      <div className="flex justify-between">
        <h4 className="text-base font-custom-bold">{t('btn.charge')}</h4>
        <p className="text-fontcolor-400">{t('tip.rate', { rate: commodities[0].quantity })}</p>
      </div>
      <GoldExchangeInput max={wallet.DIAMOND?.balance} onChange={handleExchangeInputChange} />
      <div className="text-center">
        <p className="text-fontcolor-400">{t('tip.goldRedeermable')}</p>
        <span className="text-2xl font-custom-bold text-primary-dark">{quantity * commodities[0].quantity}</span>
      </div>
      {mutation.isSuccess && (
        <Message type="success" children={t('tip.chargeGoldSuccess', { amount: quantity * commodities[0].quantity })} />
      )}
      {mutation.isError && <Message type="error" children={t('tip.chargeGoldError')} />}
      <div className="w-full">
        <Button
          type="primary"
          className="w-full"
          size="large"
          disabled={!quantity}
          loading={mutation.isPending}
          onClick={handleExhcangeClick}
        >
          {t('btn.charge')}
        </Button>
      </div>
      <div className="text-center">
        <Button
          type="link"
          className="text-sm font-normal text-fontcolor-400 underline underline-offset-1"
          onClick={() =>
            PopUpStore.setBillingPopup({
              visible: true,
              active: 'GOLD'
            })
          }
        >
          {t('btn.transactionBreakdown')}
        </Button>
      </div>
    </div>
  )
}

interface PropType {}

export default GoldExchange
