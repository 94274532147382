import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import WalletContext from '@/context/WalletContext'
import Tab from '@/component/Tab'
import Billing from '../Billing'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'

import type { TabItem } from '@/component/Tab'
import type { CurrencyCode } from '@kulee/tga-sdk/types/types/wallet'

const BillingMain: React.FC<PropType> = () => {
  const { t } = useTranslation('wallet')
  const { wallet } = useContext(WalletContext)
  const { active } = useStatePopup(PopupSelector.billingPopup)
  const [items, setItems] = useState<TabItem[]>([])

  useEffect(() => {
    const items: TabItem[] = []

    Object.keys(wallet).map(code => {
      items.push({
        label: t(`currency.${code.toLocaleLowerCase()}`),
        key: code,
        children: <Billing key={code} currency={code as CurrencyCode} />
      })
    })
    setItems(items)
  }, [wallet])

  return (
    <Tab
      items={items}
      activeKey={active}
      className="flex h-full flex-col"
      barClassName="px-md"
      destroyInactive
      contentClassName="flex-grow h-full rounded-t-2xl bg-white py-md pl-md text-[#002231]"
    />
  )
}

interface PropType {}

export default BillingMain
