import { KULEWEBVIEW_ANDROID, KULEWEBVIEW_IOS } from '@/config/constant'

const useThemeBasis = () => {
  return {
    lightHeader:
      ['regular', 'navy-blue', 'orange', 'lavender'].indexOf(window.AppConfig.data.theme) >= 0 && KULEWEBVIEW_IOS,
    lightAndroidHeader:
      ['regular', 'navy-blue', 'orange', 'lavender'].indexOf(window.AppConfig.data.theme) >= 0 && KULEWEBVIEW_ANDROID,
    lightIosHeader:
      ['regular', 'navy-blue', 'orange', 'lavender'].indexOf(window.AppConfig.data.theme) >= 0 && KULEWEBVIEW_IOS
  }
}

export default useThemeBasis
