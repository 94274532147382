import { useEffect, useMemo, useState } from 'react'
import { UC } from '@kulee/tga-sdk'

import {
  AccountRecoverOptions,
  AuthContextType,
  AuthStatus,
  LoginOptions,
  RegisterOptions,
  UpdateUserOptions,
  UserInfo
} from '@/type/auth'
import TGASDK from '@/config/TGASDK'
import logger from '@/helper/logger'
import { AVATAR, COVER } from '@/config/constant'
import { coverUrlToFit } from '@/helper'

const useProvideAuth = (): AuthContextType => {
  const [status, setStatus] = useState<AuthStatus>('unready')
  const [user, setUser] = useState<UserInfo | null>(null)

  const getUserInfo = async (): Promise<void> => {
    const user = await TGASDK.module.UC.getUserInfo()
    !user.avatar && (user.avatar = `${AVATAR.BASE_PATH}/default.png`)
    !user.cover && (user.cover = `${COVER.BASE_PATH}/default.png`)

    setUser(user)
  }

  // 用户认证初始化
  const init = async () => {
    try {
      // 快速登录
      await TGASDK.module.UC.quickLogin()
      // 获取用户信息
      await getUserInfo()

      setStatus('authorized')
    } catch (error) {
      setStatus('unauthorized')
      logger.info('auth status: unauthorized')
    }
  }

  // 从SDK登录
  const loginFromSDK = async () => {
    try {
      // 从SDK登录
      await TGASDK.module.UC.loginFromSDK()
      // 获取用户信息
      await getUserInfo()

      setStatus('authorized')
    } catch (error) {
      setStatus('unauthorized')
      logger.info('auth status: unauthorized')

      throw error
    }
  }

  // 登录
  const login = async (options: LoginOptions) => {
    // 普通帐密登录
    await TGASDK.module.UC.login(options)
    // 获取用户信息
    await getUserInfo()
    setStatus('authorized')
  }

  // 登出
  const logout = () => {
    TGASDK.module.UC.logout()

    setStatus('unauthorized')
    setUser(null)
  }

  // 注册
  const registe = async (options: RegisterOptions) => {
    // 用户注册
    await TGASDK.module.UC.register(options)
    // 获取用户信息
    await getUserInfo()
    setStatus('authorized')
  }

  // 找回密码
  const accountRecover = async (options: AccountRecoverOptions) => {
    // await TGASDK.module.UC.service.resetPassword(options)
  }

  // 更新用户信息
  const updateUser = async (options: UpdateUserOptions) => {
    await TGASDK.module.UC.updateUserInfo(options)

    // 获取用户信息
    await getUserInfo()
  }

  // 更新用户主页封面
  const updateUserCover = async (url: string) => {
    await TGASDK.module.UC.updateUserCover(coverUrlToFit(url, '2xl'))

    // 获取用户信息
    await getUserInfo()
  }

  useEffect(() => {
    TGASDK.addModule(UC)
  }, [])

  return useMemo(
    () => ({
      status,
      user,
      init,
      loginFromSDK,
      login,
      logout,
      registe,
      accountRecover,
      updateUser,
      updateUserCover
    }),
    [status, user]
  )
}

export default useProvideAuth
