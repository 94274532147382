import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { useLocation } from 'react-router-dom'

import useScreenFixed from '@/hook/useScreenFixed'
import useRuntime from '@/hook/useRuntime'
import useLinkTo from '@/hook/useLinkTo'
import { MOBILE_ANDROID, MOBILE_IOS } from '@/config/constant'
import Input from '@/component/Input'
import SearchResult from './SearchResult'
import Button from '@/component/Button'
import { ReactComponent as SearchSVG } from '@/asset/svg/search.svg'
import { ReactComponent as BackSVG } from '@/asset/svg/back.svg'

const Search: React.FC<PropType> = () => {
  const { t } = useTranslation()
  const { client } = useRuntime()
  const { linkTo } = useLinkTo()
  const location = useLocation()
  const setScreenFixed = useScreenFixed()
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string>()
  const [keyword, setKeyword] = useState<string>()
  const [formOpened, setFormOpened] = useState(false)

  const close = () => {
    setFormOpened(false)
    setValue('')
  }

  const handleSearchBtnClick = () => {
    setFormOpened(true)
    inputRef.current?.focus()
  }

  const handleBackBtnClick = () => {
    close()
    inputRef.current?.blur()
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    !value && close()
    // setTimeout(() => {
    //   document.activeElement !== inputRef.current && close()
    // }, 10)
  }

  const handlePressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    linkTo(`/search?keyword=${value}`)
    close()
  }

  const handleResulePannelClick = () => {
    inputRef.current?.focus()
  }

  const handleMaskClick = () => {
    setFormOpened(false)
    setValue('')
  }

  useEffect(() => {
    setScreenFixed(!!(client === 'mobile' && value))
  }, [value])

  const [, cancel] = useDebounce(
    () => {
      setKeyword(value)
    },
    800,
    [value]
  )

  useEffect(() => {
    close()
  }, [location])

  const inputPrefix =
    MOBILE_ANDROID || !value ? (
      <button
        className={`flex h-8 w-8 items-center justify-center sm:hidden ${formOpened ? 'block' : 'hidden'}`}
        onClick={handleBackBtnClick}
      >
        <BackSVG className="w-6" />
      </button>
    ) : (
      <button className={`flex h-8 w-8 items-center justify-center sm:hidden ${formOpened ? 'block' : 'hidden'}`}>
        <SearchSVG className="w-6 opacity-60" />
      </button>
    )

  return (
    <>
      <button className={`flex w-9 justify-start sm:hidden`} onClick={handleSearchBtnClick}>
        <SearchSVG className="w-6" />
      </button>
      <div
        className={`cus_header-search fixed top-0 z-30 flex h-14 w-full items-center transition-all sm:static sm:block sm:h-auto sm:bg-transparent lg:pr-48 ${formOpened ? 'left-0' : 'left-full'}`}
      >
        <div className="mx-auto max-w-[520px] flex-grow px-6">
          <div className="w-full sm:relative">
            <div className="flex items-center gap-2">
              <Input
                ref={inputRef}
                value={value}
                allowClear={!MOBILE_IOS}
                className="cus_header-input"
                prefix={inputPrefix}
                placeholder={t('search.placeholder')}
                onPressEnter={handlePressEnter}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {MOBILE_IOS && value && (
                <Button type="link" size="small" onClick={handleBackBtnClick}>
                  {t('btn.cancel')}
                </Button>
              )}
            </div>

            {value && (
              <div
                className="fixed left-0 top-14 h-[calc(100%-3.5rem)] w-full overflow-hidden bg-primary sm:absolute sm:top-12 sm:h-auto sm:rounded-md"
                onClick={handleResulePannelClick}
              >
                <SearchResult keyword={keyword} />
              </div>
            )}
          </div>
        </div>
      </div>
      {value && (
        <div className="fixed left-0 top-0 hidden h-full w-full bg-black/30 sm:block" onClick={handleMaskClick}></div>
      )}
    </>
  )
}

interface PropType {}

export default Search
