import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { KULEWEBVIEW, MOBILE_ANDROID } from '@/config/constant'
import Modal from '@/component/Modal'
import ArrowIcon from '@/component/Icon/Arrow'
import { ReactComponent as BackSVG } from '@/asset/svg/back.svg'

const BackBtn: React.FC<PropType> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [modalVisible, setModalVisible] = useState(false)

  const isHome = () => {
    return /^\/([^\/]+)\/([^\/]+)\/?$/.test(pathname)
  }

  const handleClick = () => {
    if (isHome() && KULEWEBVIEW) {
      setModalVisible(true)
    } else {
      navigate(-1)
    }
  }

  const handleModalCancel = () => {
    setModalVisible(false)
  }

  const handleModalOk = () => {
    window.Bridge.system.finishPage()
  }

  return (
    <>
      <button className="flex h-full w-full items-center" onClick={handleClick}>
        {MOBILE_ANDROID ? <BackSVG className="w-6 font-bold" /> : <ArrowIcon className="w-6" />}
      </button>
      <Modal
        visible={modalVisible}
        title={t('title.exitGC')}
        okText={t('btn.exitGC')}
        cancelText={t('btn.stayGC')}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        {t('message.exitGC')}
      </Modal>
    </>
  )
}

interface PropType {}

export default BackBtn
