import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useLinkTo from './useLinkTo'
import AuthContext from '@/context/AuthContext'
import { KULEWEBVIEW } from '@/config/constant'
import Toast from '@/component/Toast'

const useAppLogin = () => {
  const { t } = useTranslation('uc')
  const { linkTo } = useLinkTo()
  const { loginFromSDK } = useContext(AuthContext)

  const appLogin = async () => {
    if (KULEWEBVIEW) {
      try {
        await loginFromSDK()
        Toast.show({
          content: t('message.loginSuccess', { ns: 'uc' })
        })
      } catch (error) {
        Toast.show({
          content: t('message.loginFail', { ns: 'uc' })
        })
      }
    } else {
      linkTo('/login/')
      return
    }
  }

  return appLogin
}

export default useAppLogin
