import React, { useEffect, useMemo } from 'react'

import { ReactComponent as OKSVG } from '@/asset/svg/ok.svg'
import { ReactComponent as CloseSVG } from '@/asset/svg/close.svg'
import LoadingIcon from '../Loading/LoadingIcon'

const ToastComponent: React.FC<PropType> = ({ duration = 3000, content, icon, maskClickable = true, onClose }) => {
  useEffect(() => {
    if (duration !== 0) {
      const timeId = setTimeout(() => {
        onClose?.()
      }, duration)

      return () => {
        clearTimeout(timeId)
      }
    }
  }, [])

  const ToastIcon = useMemo(() => {
    if (!icon) {
      return null
    }

    switch (icon) {
      case 'success':
        return <OKSVG className="fill-semantic-success w-12" />
      case 'fail':
        return <CloseSVG className="fill-semantic-error w-12" />
      case 'loading':
        return (
          <div className="p-sm">
            <LoadingIcon fill="text-white" />
          </div>
        )
      default:
        break
    }
  }, [icon])

  return (
    <div
      className={`fixed left-0 top-0 z-[998] flex h-screen w-screen items-center justify-center ${maskClickable && 'pointer-events-none'}`}
    >
      <div className="mx-8 flex flex-col items-center justify-center rounded-lg bg-black/70 px-6 py-5 text-white">
        {ToastIcon && ToastIcon}
        {content}
      </div>
    </div>
  )
}

interface PropType {
  duration?: number
  icon?: 'success' | 'fail' | 'loading' | React.ReactNode
  content: string | React.ReactNode
  maskClickable?: boolean
  onClose?: () => void
}

export default ToastComponent
