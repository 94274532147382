import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTasksWithGroup } from '@/hook/data/useGrowth'
import Tab, { TabItem } from '@/component/Tab'
import Loading from '@/component/Loading'
import Empty from '@/component/Empty'
import TaskList from '../TaskList'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector } from '@/store/PopupStore'

const TaskMain: React.FC<PropType> = () => {
  const { t } = useTranslation('growth')
  const { data: tasksWithGroup, refetch } = useTasksWithGroup()
  const checkinPopupVisible = useStatePopup(PopupSelector.checkinPopupVisible)
  const topUpGoldPopupVisible = useStatePopup(PopupSelector.topUpGoldPopupVisible)

  const [items, setItems] = useState<TabItem[]>()
  const [canRefetch, setCanRefetch] = useState(false)

  useEffect(() => {
    if (!tasksWithGroup) {
      return
    }

    const items = tasksWithGroup.map(item => {
      return {
        label: t(`taskType.${item.group.toLocaleLowerCase()}`),
        key: item.group,
        children: <TaskList tasks={item.tasks} />
      }
    })
    setItems(items)
  }, [tasksWithGroup])

  useEffect(() => {
    if (checkinPopupVisible || topUpGoldPopupVisible) {
      setCanRefetch(true)
    } else {
      canRefetch && refetch()
    }
  }, [checkinPopupVisible, topUpGoldPopupVisible, canRefetch])

  if (!items) {
    return <Loading className="h-40" />
  }

  if (items && items.length <= 0) {
    return <Empty className="pt-24" />
  }

  return <Tab items={items} activeKey={items.length > 0 ? items[0].key : ''} />
}

interface PropType {}

export default TaskMain
