import type { PKGameUser } from '@/types/PKGame'

export const enum CMDS {
  // 心跳
  HEART_BIT = 'heartbeat',
  // 匹配
  MATCH = 'match.find',
  // 匹配成功
  MATCH_SUCCESS = 'match.success',
  // 退出匹配
  MATCH_QUIT = 'match.quit',

  // 进入房间
  GAME_ROOM_ENTER = 'room.enter',
  // 退出房间
  GAME_ROOM_QUIT = 'room.quit',
  // 玩家准备
  GAME_ROOM_USER_READY = 'game.ready',
  // 玩家取消准备
  GAME_ROOM_USER_CANCEL_READY = 'game.unReady',
  // 加注
  GAME_ROOM_BETDOUBLE = 'bet.double',
  // 开始倒计时
  GAME_ROOM_COUNTDOWN = 'game.countdown',
  // 倒计时完成
  GAME_ROOM_COUNTDOWN_FINISH = 'game.countdown.finish',
  // 游戏用户离线
  GAME_ROOM_USER_OFFLINE = 'game.user.offline',
  // 游戏用户在线
  GAME_ROOM_USER_ONLINE = 'game.user.online',

  // 创建游戏对局
  GAME_BATTLE_CREATE = 'game.create',
  // 关闭对局
  GAME_BATTLE_CLOSE = 'game.close',
  // 开始游戏
  GAME_START = 'game.start',
  // 加载游戏
  GAME_LOAD = 'game.load',
  // 游戏加载完成
  GAME_LOADED = 'game.loaded',
  // 游戏结束
  GAME_FINISH = 'game.finish',
  // 退出游戏
  GAME_QUIT = 'game.quit',
  // 转发游戏数据
  GAME_DATA = 'game.data',
  // 切换游戏
  GAME_CHANGE = 'game.change'
}

export const DEFAULT_USER: PKGameUser = {
  userId: '',
  nickname: '',
  avatar: '',
  role: 'default',
  bot: false,
  bet: false,
  online: true,
  ready: false
}
