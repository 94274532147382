import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'

import { getFileNameFromUrl } from '@/helper'
import AuthContext from '@/context/AuthContext'
import useStatePopup from '@/hook/useStatePopup'
import { PopupSelector, PopUpStore } from '@/store/PopupStore'
import Drawer from '../../Drawer'
import PictureChooseItem from '../component/PictureChooseItem'
import { AVATAR } from '@/config/constant'

const EditAvatarPopup: React.FC<PropType> = () => {
  const { t } = useTranslation('uc')
  const { user, updateUser } = useContext(AuthContext)
  const editAvatarPopupVisible = useStatePopup(PopupSelector.editAvatarPopupVisible)
  const [avatar, setAvatar] = useState<string>()

  const handleClose = () => {
    PopUpStore.setEditAvatarPopupVisible(false)
  }

  const handleAvatarClick = (url: string) => {
    setAvatar(url)
  }

  const [, cancel] = useDebounce(
    () => {
      avatar &&
        updateUser({
          avatar: avatar
        })
    },
    500,
    [avatar]
  )

  return (
    <Drawer
      visible={editAvatarPopupVisible}
      zIndex={901}
      onClose={handleClose}
      lockScroll={true}
      className="bg-primary-light"
      title={t('modalTitle.editAvatar')}
    >
      <div className="px-md">
        <div className="grid grid-cols-3 gap-sm">
          {AVATAR.LIST.map((code, index) => (
            <div key={index} className="aspect-square">
              <PictureChooseItem
                url={`${AVATAR.BASE_PATH}/${code}.png`}
                checked={getFileNameFromUrl(user?.avatar) === code}
                onClick={handleAvatarClick}
              />
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  )
}

interface PropType {}

export default EditAvatarPopup
