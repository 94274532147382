import ReactDOM from 'react-dom/client'

import './index.css'

import { TGASDKInit } from '@/config/TGASDK'
import { KULEWEBVIEW, MOBILE_ANDROID, MOBILE_IOS } from '@/config/constant'
import i18n, { i18nInit } from '@/i18n'
import logger from './helper/logger'

import App from './App'
import { env } from './helper'
import InjectScript from './component/InjectScript'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

window.Bridge.onReady = async () => {
  // 获取应用参数
  const { lang, theme, appCode } = KULEWEBVIEW
    ? await window.Bridge.system.gameCenterAppParam()
    : { lang: '', theme: '', appCode: '' }

  // 创建应用信息
  window.AppConfig.create({
    fallbackAppCode: process.env.SDP_ENV === 'bip' ? 'bip' : 'tga',
    getDataProvider: () => {
      return {
        lang,
        theme,
        appCode: appCode.toLocaleLowerCase()
      }
    }
  })

  logger.info('app config', window.AppConfig.data)

  window.dataKule.push('config', env, window.AppConfig.data.appId)

  // 皮肤处理
  document.documentElement.setAttribute('data-theme', window.AppConfig.data.theme)
  // 平台处理
  document.documentElement.setAttribute('data-client', MOBILE_IOS ? 'iOS' : MOBILE_ANDROID ? 'android' : 'web')

  // i18n初始化
  i18nInit(window.AppConfig.data.lang)
    .then(() => {
      document.documentElement.lang = i18n.language
      document.documentElement.dir = i18n.dir(i18n.language)

      logger.info('i18n init success')
    })
    .catch(error => {
      logger.error('i18n init error', error)
    })

  // SDK初始化
  TGASDKInit()

  // IM初始化
  try {
    KULEWEBVIEW &&
      window.Bridge.im.init({
        ymKey: window.AppConfig.data.feature.talk.key,
        ymSecret: window.AppConfig.data.feature.talk.secret
      })
  } catch (error) {
    logger.warn('im init error', error)
  }

  root.render(
    // <React.StrictMode>
    <>
      <App />
      <InjectScript />
    </>
    // </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
