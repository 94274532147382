import { createContext } from 'react'
import { AuthContextType } from '@/type/auth'

const AuthContext = createContext<AuthContextType>({
  status: 'unready',
  user: null,
  init: () => new Promise(() => {}),
  loginFromSDK: () => new Promise(() => {}),
  login: () => new Promise(() => {}),
  logout: () => {},
  registe: () => new Promise(() => {}),
  accountRecover: () => new Promise(() => {}),
  updateUser: () => new Promise(() => {}),
  updateUserCover: () => new Promise(() => {})
})

export default AuthContext
