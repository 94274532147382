import React, { useContext } from 'react'

import AuthContext from '@/context/AuthContext'
import Forbidden from '../Forbidden'

const AuthRequired: React.FC<PropType> = ({ message, children }) => {
  const { status } = useContext(AuthContext)

  if (status !== 'authorized') {
    return <Forbidden message={message} />
  }

  const Comp = children
  return <>{React.isValidElement(children) ? children : <Comp />}</>
}

interface PropType {
  message?: string
  children: any
}

export default AuthRequired
